import useDevice, { DeviceNames, Devices } from "@/hooks/useDevice";
import React, { useMemo } from "react";

export interface PaginationProps {
  page: number;
  maxPage: number;
  onPageChange: (page: number) => unknown;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  maxPage,
  onPageChange,
}) => {
  const handlePageChange = (newPage: number) => {
    onPageChange(newPage);
  };

  const hasNext = useMemo(() => {
    return page < maxPage;
  }, [page, maxPage]);

  const hasPrev = useMemo(() => {
    return page > 1;
  }, [page, maxPage]);

  const handlePrevClick = () => {
    if (hasPrev) {
      handlePageChange(page - 1);
    }
  };

  const handleNextClick = () => {
    if (hasNext) {
      handlePageChange(page + 1);
    }
  };

  const isMobile = useDevice(Devices[DeviceNames.MOBILE]);
  const isTablet = useDevice(Devices[DeviceNames.TABLET]);

  const renderPageButtons = () => {
    const buttons = [];

    for (let i = 1; i <= maxPage; i++) {
      buttons.push(
        <button
          key={`pg-button-${i}`}
          className={`${i === page ? "active" : ""}`}
          onClick={() => {
            handlePageChange(i);
          }}
        >
          {i.toString().padStart(2, "0")}
        </button>,
      );
    }

    return buttons;
  };

  if (isMobile || isTablet) {
    return (
      <div className="row mt--30">
        <div className="col-12">
          <div className="text-center">
            <div className="pagination">
              {hasPrev && (
                <button onClick={handlePrevClick}>
                  <i
                    className="fal fa-angle-double-left"
                    style={{
                      marginLeft: "0",
                      fontSize: "24px",
                      marginTop: "-3px",
                    }}
                  ></i>
                </button>
              )}
              <button> {page.toString().padStart(2, "0")}</button>
              <div></div>
              {hasNext && (
                <button onClick={handleNextClick}>
                  <i className="fal fa-angle-double-right"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row mt--30">
      <div className="col-12">
        <div className="text-center">
          <div className="pagination">
            {hasPrev && (
              <button onClick={handlePrevClick}>
                <i
                  className="fal fa-angle-double-left"
                  style={{
                    marginLeft: "0",
                    fontSize: "24px",
                    marginTop: "-3px",
                  }}
                ></i>
              </button>
            )}
            {renderPageButtons().map((button) => button)}
            <div></div>
            {hasNext && (
              <button onClick={handleNextClick}>
                <i className="fal fa-angle-double-right"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
