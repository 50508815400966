import React, { type FC } from "react";
import { useLocation } from "@reach/router";

interface Props {
  name: string;
  pathName: string;
  imgUrl?: string;
}

const Breadcrumb: FC<Props> = ({
  name,
  pathName,
  imgUrl = "/images/breadcrumb/bc01.png",
}) => {
  const { pathname } = useLocation();
  const isCareers = pathname.includes("/careers/details/");
  const isNews =
    pathname.includes("/news/") && pathname.split("/")[2].length > 0;
  const isCapability =
    pathname.includes("/capabilities/") && pathname.split("/")[2].length > 0;
  const isAbout =
    pathname.includes("/about/") && pathname.split("/")[2].length > 0;
  const isMedia =
    pathname.includes("/media/") && pathname.split("/")[2].length > 0;
  const isOnlyCapabilityPath =
    pathname.includes("/capabilities") &&
    !pathname.includes("/repair-services");
  pathName = pathName.replace("(Video)", "");
  name = pathName.replace("(Video)", "");

  return (
    <div
      className="rts-breadcrumb-area breadcrumb-bg bg_image"
      style={{
        backgroundImage: `url(${imgUrl})`,
      }}
    >
      <div className="breadcrumb-bg-overlay"></div>
      <div className="container z-i-breadcumb">
        <div className="row align-items-center">
          <div
            className={`col-xl-6 ${
              isOnlyCapabilityPath ? "col-lg-5" : "col-lg-6"
            } col-md-12 col-sm-12 col-12 breadcrumb-1`}
          >
            <div
              data-before={name}
              className="rts-title-area custom text-starts breadcumb-title"
            >
              <h2 className="title how-title">{name}</h2>
            </div>
          </div>
          <div
            className={`col-xl-6 ${
              isOnlyCapabilityPath ? "col-lg-7" : "col-lg-6"
            } col-md-12 col-sm-12 col-12 breadcrumb-1`}
            style={{ zIndex: "5" }}
          >
            <div className="bread-tag">
              <a href="/">Home</a>
              <span style={{ marginRight: "8px" }}>/</span>
              <a
                href={
                  isCareers
                    ? "/careers"
                    : isNews
                    ? "/news"
                    : isCapability
                    ? "/capabilities"
                    : isMedia
                    ? "/media"
                    : isAbout
                    ? "/about"
                    : "#"
                }
                className={`${
                  !isCareers && !isNews && !isCapability && !isMedia && !isAbout
                    ? "active"
                    : ""
                }`}
              >
                {isCareers
                  ? "Careers"
                  : isNews
                  ? "News"
                  : isCapability
                  ? "Capabilities"
                  : isMedia
                  ? "Media"
                  : isAbout
                  ? "About Us"
                  : pathName}
              </a>
              {(isCareers || isNews || isCapability || isMedia || isAbout) && (
                <>
                  <span style={{ marginRight: "8px" }}>/</span>
                  <a href="#" className="active">
                    {pathName}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
