import React from "react";
import PostItem from "@/components/News/PostItem";
import Pagination from "@/components/Common/Pagination";
import { BlogContext, Types } from "@/context/BlogContext";

const News = () => {
  return (
    <BlogContext.Consumer>
      {({ state, setPage, dispatch }) => {
        return (
          <>
            <div className="row posts-wrapper g-5">
              {state.isLoading ? (
                <h3>Loading...</h3>
              ) : state.totalPost > 0 ? (
                state.posts.map((post) => (
                  <PostItem key={post.id} post={post} />
                ))
              ) : (
                <h2>No posts found</h2>
              )}
            </div>
            {!state.isLoading ? (
              <Pagination
                page={state.page}
                maxPage={Math.ceil(state.totalPost / state.MAX_SIZE)}
                onPageChange={(newp) => {
                  dispatch({
                    type: Types.SET_PAGE,
                    payload: {
                      page: newp,
                    },
                  });
                }}
              />
            ) : (
              ""
            )}
          </>
        );
      }}
    </BlogContext.Consumer>
  );
};

export default News;
