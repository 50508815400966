/* eslint-disable @typescript-eslint/no-floating-promises */
import React from "react";
import { format } from "date-fns";
import { type PostListItem } from "@/types";
import { Link, navigate } from "gatsby";
import { parseDate } from "@/components/Careers/Jobs";
interface Post {
  post: PostListItem;
}

const PostItem: React.FC<Post> = ({ post }) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12 col-12" key={post.id}>
      <div className="blog-grid-inner">
        <div className="blog-header">
          <Link to={`/news/slug/${post.slug}`} className="thumbnail">
            <img src={post.imageUrl} alt="Post Image" />
          </Link>
          <div className="blog-info">
            <div className="user">
              <span
                onClick={() => {
                  navigate(`/news/author/${post?.author}`);
                }}
              >
                <i className="fal fa-user-circle"></i>
                <span>{`by ${post?.author}`}</span>
              </span>
            </div>
            {post.tags?.length > 0 && (
              <div className="user">
                <i className="fal fa-tags"></i>
                {post.tags.slice(0, 1).map((tag) => (
                  <span
                    className="mr--20"
                    key={tag.id}
                    onClick={() => {
                      navigate(`/news/tag/${tag.slug}`);
                    }}
                  >
                    {tag.name.length < 10
                      ? tag.name
                      : tag.name?.substring(0, 10).concat("...")}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="date">
            <h6 className="title">{format(parseDate(post.createdAt), "d")}</h6>
            <span>{format(parseDate(post.createdAt), "MMM")}</span>
          </div>
        </div>
        <div className="blog-body">
          <Link to={`/news/slug/${post.slug}`}>
            <h5 className="title">{post.title}</h5>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PostItem;
