/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, type FC, type ReactNode, useEffect } from "react";
import { BlogContext } from "@/context/BlogContext";
import { isBrowser } from "@/components/Header";
import { type CategoryItem } from "@/types";
import { navigate } from "gatsby";

interface Props {
  children?: ReactNode;
}
const BlogLayout: FC<Props> = ({ children }) => {
  const querySearch =
    isBrowser && window.localStorage.getItem("search") !== null
      ? String(window.localStorage.getItem("search"))
      : "";
  const [search, setInputSearch] = useState(querySearch);

  useEffect(() => {
    setInputSearch(querySearch);
  }, [querySearch]);

  return (
    <BlogContext.Consumer>
      {({ state }) => {
        return (
          <div className="rts-news-area rts-section-gap pt--40 pb--40">
            <div className="container">
              <div className="row g-5">
                <div className="col-xl-8 col-md-12 col-sm-12 col-12 pr--40 pr_md--0 pr_sm-controler--0">
                  {children}
                </div>

                {state.posts !== undefined && (
                  <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 news-sidebar">
                    <div className="news-search-wrapper">
                      <h4>Search Here</h4>

                      <div className="search-area">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            if (search.length > 0) {
                              navigate(`/news/querySearch/${search}`);
                            }
                          }}
                        >
                          <input
                            className="form-control"
                            type="text"
                            name="search"
                            value={search}
                            placeholder="Enter Keyword"
                            onChange={(e) => {
                              setInputSearch(e.target.value);
                            }}
                          />
                          <button className="news-search-btn" type="submit">
                            <i className="far fa-search"></i>
                          </button>
                        </form>
                      </div>
                    </div>

                    <div className="categories-wrapper">
                      <h4>Categories</h4>
                      <div>
                        {state.categories
                          .slice(0, 5)
                          .map((category: CategoryItem) => (
                            <ul key={category.id} className="single-category">
                              <li
                                onClick={() => {
                                  navigate(`/news/category/${category.id}`);
                                }}
                              >
                                {category.name}
                                <img
                                  src="/images/icon/arrow-right.svg"
                                  alt="Arrow"
                                />
                              </li>
                            </ul>
                          ))}
                      </div>
                    </div>

                    {/*  <div className="recent-posts-wrapper">
                      <h4>Recent Posts</h4>
                      <div>
                        {state.recentPosts.map((post) => (
                          <div
                            key={`recent-post-${post.id}`}
                            className="single-recent-post"
                          >
                            <div className="d-flex">
                              <div className="thumb"></div>
                              <div className="content-area">
                                <div className="user">
                                  <i className="fal fa-clock"></i>
                                  <span>
                                    {format(
                                      parseDate(post.createdAt),
                                      "d MMMM, yyyy",
                                    )}
                                  </span>
                                </div>
                                <Link
                                  className="post-title"
                                  to={`/news/slug/${post.slug}`}
                                >
                                  <h6 className="title">{post.title}</h6>
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                         <div className="popular-tags-wrapper">
                      <h4>Popular Tags</h4>
                      <div>
                        {state.tags.map((tag) => (
                          <div
                            onClick={() => {
                              if (isBrowser) {
                                window.location.replace(
                                  `/news/tag/${String(tag.slug)}`,
                                );
                              }
                            }}
                            key={tag.id}
                            className="single-tag"
                          >
                            {tag.name}
                          </div>
                        ))}
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </BlogContext.Consumer>
  );
};

export default BlogLayout;
