import React, { useEffect } from "react";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import Partners from "@/components/Home/Partners";
import Breadcrumb from "@/components/Common/Breadcrumb";
import { useLocation } from "@reach/router";
import News from "@/components/News";
import BlogLayout from "@/components/Layout/BlogLayout";
import { useBlog } from "@/context/BlogContext";

const PostsByAuthor = ({ params }: any) => {
  const { author } = params;
  const { setAuthor, clearStorage } = useBlog();

  useEffect(() => {
    ["search", "category", "tag"].forEach((el) => {
      clearStorage(el);
    });
    setAuthor(author);
    window.localStorage.setItem("author", author);
  }, [author]);

  return (
    <Layout>
      <SEO title="News" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Latest Posts" pathName="Author" />
      <BlogLayout>
        <News />
      </BlogLayout>
      <Partners />
    </Layout>
  );
};

export default PostsByAuthor;
